.clients-container {
    
    .user {
        border: 1px solid #ccc;
        margin-bottom: 2rem;
        max-width: 350px;
        padding: 0.5rem;
    }

    .clients-container__button-container {
        margin: 0.25rem;
    }
}