.addExercisesToWorkout-container {
    display: flex;
    flex-direction: row;
    padding: map-get($spacing, lg);
  
    h1 {
      margin-bottom: map-get($spacing, xl);
      color: map-get(map-get($colors, text), primary);
    }
  
    .addExercisesToWorkout-container__exercises {
      flex: 1;
      margin-right: map-get($spacing, xl);
    }
  
    .addExercisesToWorkout-container__left {
      flex: 1;
    }
  
    .container {
      background-color: map-get(map-get($colors, background), secondary);
      display: flex;
      flex-direction: column;
    }
      
    .exerciseHeading {
      font-size: map-get(map-get($typography, sizes), xl);
      margin-bottom: map-get($spacing, md);
      margin-left: 0;
      color: map-get(map-get($colors, text), primary);
      font-weight: map-get(map-get($typography, weights), semibold);
    }
      
    .itemContainer {
      @include card;
      margin-bottom: map-get($spacing, lg);
      padding: map-get($spacing, lg);
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
  
      &.superset {
        border: 2px solid map-get(map-get($colors, accent), primary);
      }
  
      &.selecting {
        border: 2px dashed map-get(map-get($colors, accent), primary);
      }
  
      .itemContainer__button {
        width: map-get($spacing, xl);
        height: map-get($spacing, xl);
        background-color: map-get(map-get($colors, status), error);
        position: absolute;
        top: map-get($spacing, md);
        right: map-get($spacing, md);
        border: none;
        cursor: pointer;
        border-radius: map-get($border-radius, sm);
        color: map-get(map-get($colors, text), primary);
        
        &:hover {
          background-color: darken(map-get(map-get($colors, status), error), 10%);
        }
      }
    }
      
    .itemText {
      font-size: map-get(map-get($typography, sizes), md);
      font-weight: map-get(map-get($typography, weights), semibold);
      flex: 1;
      margin-left: map-get($spacing, md);
      color: map-get(map-get($colors, text), primary);
    }
      
    .inputContainer {
      padding: map-get($spacing, sm);
      display: flex;
      align-items: center;
      background-color: map-get(map-get($colors, background), tertiary);
      margin-bottom: map-get($spacing, sm);
      border-radius: map-get($border-radius, md);
      border: 1px solid map-get(map-get($colors, background), tertiary);
    }
      
    .input {
      @include input-field;
      width: map-get($spacing, xxl) * 3;
      height: map-get($spacing, xxl) * 2;
      margin-right: map-get($spacing, md);
      text-align: center;
      border: 1px solid map-get(map-get($colors, background), tertiary);
      background-color: map-get(map-get($colors, background), secondary);
      
      &:focus {
        border-color: map-get(map-get($colors, accent), primary);
      }
    }
      
    .buttonContainer {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }
      
    .button {
      background-color: map-get(map-get($colors, accent), primary);
      border-radius: map-get($border-radius, md);
      padding: map-get($spacing, md);
      width: map-get($spacing, xxl) + map-get($spacing, md);
      margin-left: map-get($spacing, xs);
      color: map-get(map-get($colors, text), primary);
      font-size: map-get(map-get($typography, sizes), md);
      text-align: center;
      border: none;
      cursor: pointer;
      
      &:hover {
        background-color: map-get(map-get($colors, accent), primary-hover);
      }
    }
  
    .supersetButton {
      margin-top: map-get($spacing, md);
      background-color: map-get(map-get($colors, accent), primary);
      border-radius: map-get($border-radius, md);
      padding: map-get($spacing, md);
      color: map-get(map-get($colors, text), primary);
      font-size: map-get(map-get($typography, sizes), md);
      text-align: center;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: map-get(map-get($colors, accent), primary-hover);
      }
  
      &.active {
        background-color: map-get(map-get($colors, accent), primary);
      }
  
      &.selecting {
        background-color: map-get(map-get($colors, status), success);
      }
  
      &.pairing {
        background-color: map-get(map-get($colors, status), warning);
      }
    }
  
    .add-workout-container__select-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      @include card;
      padding: map-get($spacing, lg);
  
      .add-workout-container__select-container__user,
      .add-workout-container__select-container__program,
      .add-workout-container__select-container__workout {
        display: flex;
        flex-direction: column;
        margin-bottom: map-get($spacing, xl);
  
        h2 {
          color: map-get(map-get($colors, text), primary);
          font-size: map-get(map-get($typography, sizes), lg);
          font-weight: map-get(map-get($typography, weights), semibold);
          margin-bottom: map-get($spacing, sm);
        }
      }
  
      select {
        @include input-field;
        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
  
      form {
        padding-top: map-get($spacing, lg);
  
        button {
          @include button-primary;
          width: 100%;
        }
      }
    }
  
    .add-workout-container__input-container {
      max-width: 400px;
    
      form {
        display: flex;
        flex-direction: column;
      }
    
      input {
        @include input-field;
      }
    
      button {
        @include button-primary;
        margin-top: map-get($spacing, lg);
      }
    }
  }