@import "./colors";

.reset-password-form {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: $primary-bg;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: $primary-text;
  }
  
  .reset-password-form h2 {
    text-align: center;
    margin-bottom: 20px;
    // color: #333;
  }
  
  .reset-password-form form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-form label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .reset-password-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: $secondary-bg;
    width: 100%;
  }
  
  .reset-password-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .reset-password-form button:hover {
    background-color: #0056b3;
  }

  .reset-password-form {

    .loginForm__usernameContainer {
        display: flex;
        flex-direction: column;
    }
    .loginForm__passwordContainer {
        display: flex;
        flex-direction: column;
    }

    p {
        margin-top: 1rem;
        color: red;
    }

}
  