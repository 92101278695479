@import "./colors";
@import 'variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rajdhani", sans-serif;
}

button, 
[role="button"] {
  cursor: pointer;
}

body {
  background-color: map-get(map-get($colors, background), primary);
  color: map-get(map-get($colors, text), primary);
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, lg);
}

.input-primary {
  @include input-field;
}

.cta-1 {
  @include button-primary;
}

.modal-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  @include card;
  padding: map-get($spacing, xl);
  max-width: 500px;
  width: 90%;

  h3 {
    color: map-get(map-get($colors, text), primary);
    font-size: map-get(map-get($typography, sizes), xl);
    margin-bottom: map-get($spacing, lg);
  }
}