// _header.scss
@import 'variables';

.header-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: map-get($spacing, xl);
}

.header {
  width: 100%;
  height: 160px;
  background: linear-gradient(
    135deg,
    map-get(map-get($colors, background), secondary) 0%,
    map-get(map-get($colors, background), primary) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  // Optional: subtle pattern overlay
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
      circle at 25px 25px,
      map-get(map-get($colors, navigation), border) 1px,
      transparent 0
    );
    background-size: 50px 50px;
    opacity: 0.1;
  }

  // Logo styling
  #logoName {
    font-size: 48px;
    font-weight: map-get(map-get($typography, weights), bold);
    color: map-get(map-get($colors, text), primary);
    letter-spacing: 2px;
    position: relative;
    
    // Optional: subtle text shadow
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
    // Optional: accent bar under logo
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 3px;
      background-color: map-get(map-get($colors, accent), primary);
      border-radius: 2px;
    }
  }
}

// Top bar with actions
.header-top {
  width: 100%;
  height: 40px;
  background-color: map-get(map-get($colors, background), tertiary);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 map-get($spacing, xl);

  .logout-button {
    padding: map-get($spacing, xs) map-get($spacing, md);
    background-color: transparent;
    border: 1px solid map-get(map-get($colors, status), error);
    color: map-get(map-get($colors, status), error);
    border-radius: map-get($border-radius, sm);
    font-size: map-get(map-get($typography, sizes), sm);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: map-get(map-get($colors, status), error);
      color: white;
    }
  }
}

// Optional: Responsive adjustments
@media (max-width: 768px) {
  .header {
    height: 120px;

    #logoName {
      font-size: 36px;
    }
  }
}