.spinner {
    display: inline-block;
    position: relative;
    
    &--small {
      width: map-get($spacing, lg);  // 16px
      height: map-get($spacing, lg); // 16px
    }
    
    &--medium {
      width: 48px;  // Doubled size
      height: 48px; // Doubled size
    }
    
    &--large {
      width: 64px;  // Doubled size
      height: 64px; // Doubled size
    }
  
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: map-get(map-get($colors, accent), primary);
      animation: spin 0.8s linear infinite;
    }
  
    &--secondary::after {
      border-top-color: map-get(map-get($colors, text), secondary);
    }
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }