@import "./colors";

.create-program-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    // background-color: $secondary-bg;
    max-width: 500px;
    margin: 20px auto;
    
     h2 {
      margin-bottom: 20px;
    }
    
    .create-program-container__name {
      margin-bottom: 15px;
    }

    label {
     display: block;
     margin-bottom: 5px;
     font-weight: bold;
   }
   
    input {
     width: 100%;
     padding: 8px;
     border: 1px solid #ccc;
     border-radius: 4px;
     background-color: $secondary-bg;
   }
   
   button {
    background-color: rgb(0, 123, 255);
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    color: white;
    font-size: 16px;
    text-align: center;
    border: none;
    cursor: pointer;
    width: 100%;
   }

   button:hover {
    background-color: rgb(43, 145, 255);
   }

  }
  
  .create-program-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: $secondary-bg;
  }
  
  .programs-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: $primary-bg;
    max-width: 500px;
    margin: 20px auto;

  }
  .error-message {
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .programs-container h2 {
    margin-bottom: 20px;
  }
  
  .programs-container__program {
    margin-bottom: 10px;
    padding: 10px;
    // border: 1px solid #ddd;
    border-radius: 4px;
    // background-color: #fff;
    transition: background-color 0.3s ease;
    background-color: $secondary-bg;
  }
  
  .programs-container__program:hover {
    background-color: $input-bg;
  }
  
  .programs-container__program a {
    text-decoration: none;
    color: #333;
  }
  
  .programs-container__program a:hover {
    text-decoration: underline;
  }
  
