@import "./colors";

.training-container {

.container {
    background-color: $primary-bg;
    display: flex;
    flex-direction: column;
  }
  
  .exerciseHeading {
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: 0;
  }
  
  .itemContainer {
    border: 1px solid white;
    margin-bottom: 20px;
    padding: 16px;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 8px;
    background-color: $primary-bg;
  }
  
  .itemText {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    margin-left: 10px;
    background-color: $secondary-bg;
  }
  
  .inputContainer {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    background-color: $secondary-bg;
    margin-bottom: 5px;
  }
  
  .input {
    width: 40px;
    height: 40px;
    border-color: $primary-text;
    border-width: 1px;
    margin-right: 8px;
    text-align: center;
    background-color: $input-bg;
    color: $primary-text;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .button {
    background-color: #007bff;
    border-radius: 4px;
    padding: 8px;
    width: 35px;
    margin-left: 2px;
    color: white;
    font-size: 16px;
    text-align: center;
    border: none;
    cursor: pointer;
  }
  
  .finishButton {
    background-color: #007bff;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    color: white;
    font-size: 16px;
    text-align: center;
    border: none;
    cursor: pointer;
  }
}