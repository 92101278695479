.signup {

    form {
        margin: 0 auto;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        max-width: 400px;

        div {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
        }
    }

}