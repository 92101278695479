@import "./variables";

.add-exercises-form {
    @include card; // This gives us the consistent card styling
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin: map-get($spacing, xl) auto;
    padding: map-get($spacing, xl);

    input, select {
        @include input-field; // This applies your consistent input styling
        margin-bottom: map-get($spacing, md);
    }

    label {
        display: block;
        margin-bottom: map-get($spacing, sm);
        font-weight: map-get(map-get($typography, weights), semibold);
        color: map-get(map-get($colors, text), primary);
    }

    .add-exercises-form__input-container,
    .add-exercises-form__select-container {
        display: flex;
        flex-direction: column;
        margin-top: map-get($spacing, lg);
    }

    .add-exercises-form__select-container {
        margin-bottom: map-get($spacing, lg);
    }

    button {
        @include button-primary; // This applies your consistent button styling
        margin-top: map-get($spacing, lg);
    }
}

.exercise-container {
    @include card;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin: map-get($spacing, xl) auto;
    padding: map-get($spacing, xl);
    
    h2 {
        color: map-get(map-get($colors, text), primary);
        font-size: map-get(map-get($typography, sizes), xxl);
        margin-bottom: map-get($spacing, xl);
        font-weight: map-get(map-get($typography, weights), bold);
    }

    h3 {
        color: map-get(map-get($colors, accent), primary); // Using accent color for category headers
        font-size: map-get(map-get($typography, sizes), xl);
        margin-top: map-get($spacing, xl);
        margin-bottom: map-get($spacing, md);
        font-weight: map-get(map-get($typography, weights), semibold);
        padding-bottom: map-get($spacing, xs);
        border-bottom: 2px solid map-get(map-get($colors, accent), primary); // Adding an accent underline
    }

    ul {
        list-style: none;
        padding: 0;
        
        li {
            color: map-get(map-get($colors, text), primary);
            padding: map-get($spacing, md) map-get($spacing, sm);
            font-size: map-get(map-get($typography, sizes), md);
            
            &:not(:last-child) {
                border-bottom: 1px solid map-get(map-get($colors, background), tertiary);
            }

            &:hover {
                background-color: map-get(map-get($colors, background), tertiary);
                border-radius: map-get($border-radius, sm);
            }
        }
    }
}