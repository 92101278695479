// Base container
.program-editor {
    display: flex;
    height: calc(100vh - #{map-get($spacing, xxl) * 3});
    gap: map-get($spacing, xl);
    padding: map-get($spacing, xl);
    background-color: map-get(map-get($colors, background), primary);
    overflow: hidden; // Prevent outer overflow
  }
  
  // Sidebar
  .program-editor__sidebar {
    width: 300px;
    @include card;
    padding: map-get($spacing, lg);
    border-radius: map-get($border-radius, lg);
  }
  
  .program-editor__sidebar__search {
    position: relative;
    margin-bottom: map-get($spacing, lg);
  }
  
  .program-editor__sidebar__search input {
    @include input-field;
    padding-left: map-get($spacing, xl) * 1.5;
    background-color: map-get(map-get($colors, background), primary);
  }
  
  .program-editor__sidebar__search input:focus {
    border-color: map-get(map-get($colors, accent), primary);
  }
  
  .program-editor__sidebar__search .search-icon {
    position: absolute;
    left: map-get($spacing, md);
    top: 50%;
    transform: translateY(-50%);
    color: map-get(map-get($colors, text), secondary);
  }
  
  .program-editor__sidebar__list {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
  }
  
  .program-editor__sidebar__list button {
    width: 100%;
    text-align: left;
    padding: map-get($spacing, lg);
    margin-bottom: map-get($spacing, sm);
    border-radius: map-get($border-radius, lg);
    border: none;
    background-color: transparent;
    color: map-get(map-get($colors, text), primary);
    transition: all 0.2s ease;
  }
  
  .program-editor__sidebar__list button:hover {
    background-color: map-get(map-get($colors, background), primary);
  }
  
  .program-editor__sidebar__list button.selected {
    background-color: map-get(map-get($colors, accent), primary);
  }
  
  .program-editor__sidebar__list button.selected p {
    color: rgba(map-get(map-get($colors, text), primary), 0.8);
  }
  
  .program-editor__sidebar__list button h3 {
    font-weight: map-get(map-get($typography, weights), semibold);
    font-size: map-get(map-get($typography, sizes), md);
    margin-bottom: map-get($spacing, xs);
  }
  
  .program-editor__sidebar__list button p {
    font-size: map-get(map-get($typography, sizes), sm);
    color: map-get(map-get($colors, text), secondary);
  }
  
  // Main content
  .program-editor__content {
    @include card;
    flex: 1;
    padding: map-get($spacing, xl);
    border-radius: map-get($border-radius, lg);
    display: flex;
    flex-direction: column;
    overflow: hidden; // Contain the overflow
  }
  
  .program-editor__content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: map-get($spacing, xl);
  }
  
  .program-editor__content__header__breadcrumb {
    display: flex;
    align-items: center;
    gap: map-get($spacing, sm);
    color: map-get(map-get($colors, text), secondary);
    font-size: map-get(map-get($typography, sizes), md);
  
    span {
      opacity: 0.8;
    }
  
    .chevron {
      opacity: 0.6;
    }
  }
  
  .program-editor__content__header__actions button {
    @include button-primary;
    display: flex;
    align-items: center;
    gap: map-get($spacing, sm);
    margin-top: 0.25rem;
  }
  
  // Programs grid
  .program-editor__content__programs {
    height: 100%;
    width: 100%;

    .delete-program-button {
      position: absolute;  // Add this
      top: map-get($spacing, md);  // Add this
      right: map-get($spacing, md);  // Add this
      padding: map-get($spacing, sm) map-get($spacing, md);
      background-color: transparent;
      border: 1px solid map-get(map-get($colors, status), error);
      color: map-get(map-get($colors, status), error);
      border-radius: map-get($border-radius, sm);
      cursor: pointer;
      transition: all 0.2s ease;
      z-index: 1;  // Add this to ensure it stays on top
    
      &:hover {
        background-color: map-get(map-get($colors, status), error);
        color: white;
      }
    }
  }
  
  .program-card {
    background-color: map-get(map-get($colors, background), primary);
    padding: map-get($spacing, lg);
    border-radius: map-get($border-radius, md);
    border: 1px solid rgba(map-get(map-get($colors, text), primary), 0.1);
    transition: all 0.2s ease;
    margin-bottom: 1rem;
    position: relative;  // Add this to allow absolute positioning of children
    
    h3 {
      font-size: map-get(map-get($typography, sizes), lg);
      font-weight: map-get(map-get($typography, weights), semibold);
      margin-bottom: map-get($spacing, md);
      color: map-get(map-get($colors, text), primary);
    }
  
    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  
  .program-card h3 {
    font-size: map-get(map-get($typography, sizes), lg);
    font-weight: map-get(map-get($typography, weights), semibold);
    margin-bottom: map-get($spacing, md);
    color: map-get(map-get($colors, text), primary);
    opacity: 0.9;
  }
  
  // Workout list
  .workout-list {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
  }
  
  .workout-button {
    width: 100%;
    text-align: left;
    padding: map-get($spacing, md);
    background-color: map-get(map-get($colors, background), secondary);
    border: 1px solid map-get(map-get($colors, background), tertiary);
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), secondary);
    transition: all 0.2s ease;
    cursor: pointer;
  
    &:hover {
      background-color: map-get(map-get($colors, background), tertiary);
      color: map-get(map-get($colors, text), primary);
    }
  }
  
  .workout-item {
    width: 100%;
    text-align: left;
    padding: map-get($spacing, md);
    background-color: map-get(map-get($colors, background), primary);
    border: none;
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), primary);
    transition: background-color 0.2s ease;
  }
  
  .workout-item:hover {
    background-color: map-get(map-get($colors, background), tertiary);
  }

  // Back button
  .program-editor__content__back-button {
    display: flex;
    align-items: center;
    gap: map-get($spacing, xs);
    color: map-get(map-get($colors, text), secondary);
    padding: map-get($spacing, xs) 0;
    margin-bottom: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
    transition: color 0.2s ease;
    font-size: map-get(map-get($typography, sizes), sm);
  
    &:hover {
      color: map-get(map-get($colors, text), primary);
    }
  }
  
  // Program view
  .program-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  
    .program-view__exercises {
      flex: 1;
      overflow-y: auto;
      padding: 0 map-get($spacing, md);
      margin: 0 -#{map-get($spacing, md)}; // Compensate for padding
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, md);
    }
  }
  
  .program-view__title {
    font-size: map-get(map-get($typography, sizes), xl);
    font-weight: map-get(map-get($typography, weights), semibold);
    color: map-get(map-get($colors, text), primary);
    margin: map-get($spacing, md) 0;
  }
  
  .program-view__workout {
    width: 100%;
    text-align: left;
    padding: map-get($spacing, lg);
    background-color: map-get(map-get($colors, background), secondary);
    border: 1px solid rgba(map-get(map-get($colors, text), primary), 0.1);
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), primary);
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    &:hover {
      background-color: map-get(map-get($colors, background), tertiary);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    &:active {
      transform: translateY(0);
    }
  }

  .view-workouts-button {
    width: 100%;
    text-align: center;
    padding: map-get($spacing, md);
    background-color: map-get(map-get($colors, background), tertiary);
    border: none;
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), primary);
    transition: all 0.2s ease;
    cursor: pointer;
    
    &:hover {
      background-color: map-get(map-get($colors, accent), primary);
      color: white;
    }
  }

  .program-view__workouts {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
  }

  .program-view__workout {
    width: 100%;
    text-align: left;
    padding: map-get($spacing, lg);
    background-color: map-get(map-get($colors, background), tertiary); // Darker background for more contrast
    border: 1px solid rgba(map-get(map-get($colors, text), primary), 0.1);
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), primary);
    transition: all 0.2s ease;
    cursor: pointer;
    margin-bottom: map-get($spacing, md); // Added margin bottom
  
    h3 {
      font-size: map-get(map-get($typography, sizes), md);
      font-weight: map-get(map-get($typography, weights), medium);
      opacity: 0.9;
    }
  
    &:hover {
      background-color: lighten(map-get(map-get($colors, background), tertiary), 5%);
      border-color: map-get(map-get($colors, accent), primary);
    }
  
    &:active {
      transform: translateY(0);
    }
  }

//   Program view ----------------------------------------------------------------------------------------------------------------------------

.program-view__exercises {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, lg);
  }
  
  .exercise-item {
    background-color: map-get(map-get($colors, background), secondary);
    border: 1px solid map-get(map-get($colors, background), tertiary);
    border-radius: map-get($border-radius, lg);
    padding: map-get($spacing, lg);
    margin-bottom: map-get($spacing, md);
    transition: all 0.2s ease;

    &:hover {
      border-color: map-get(map-get($colors, accent), primary);
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: map-get($spacing, md);
      position: relative; // For X button positioning

      &__content {
        display: flex;
        flex-direction: column;
        gap: map-get($spacing, xs);
  
        h4 {
          margin: 0;
          font-size: map-get(map-get($typography, sizes), md);
          font-weight: map-get(map-get($typography, weights), semibold);
          color: white;
        }
  
        .superset-info {
          margin: 0;
          font-size: map-get(map-get($typography, sizes), md);
          color: rgba(255, 255, 255, 0.6);
        }
      }
  
      h4 {
        font-size: map-get(map-get($typography, sizes), lg);
        font-weight: map-get(map-get($typography, weights), semibold);
        color: white;
        margin: 0;
      }
  
      button {
        color: map-get(map-get($colors, status), error) !important; 
        background: none;
        border: none;
        cursor: pointer;
        padding: map-get($spacing, xs);
        border-radius: map-get($border-radius, sm);
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: -#{map-get($spacing, md)}; // Move slightly outside the header
        top: -#{map-get($spacing, xs)};
  
        &:hover {
          background-color: rgba(255, 82, 82, 0.1);
        }
      }
    }
  
    &__controls {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: map-get($spacing, md);
      margin-bottom: map-get($spacing, md);
  
      .control-group {
        label {
          display: block;
          font-size: map-get(map-get($typography, sizes), sm);
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: map-get($spacing, xs);
          text-transform: capitalize;
        }
  
        .control-input {
          display: grid;
          grid-template-columns: auto 1fr auto;
          align-items: center;
          background-color: rgba(13, 17, 23, 0.8);
          border-radius: map-get($border-radius, md);
          padding: 2px;
          background-color: map-get(map-get($colors, background), primary);
          border: 1px solid map-get(map-get($colors, background), tertiary);
  
          button {
            padding: map-get($spacing, xs) map-get($spacing, sm);
            color: white;
            border: none;
            background-color: #2a3545;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            height: 36px;
            border-radius: map-get($border-radius, sm);
            margin: 2px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  
            &:hover {
              background-color: #343f52;
              // transform: translateY(-1px);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            }
  
            &:active {
              transform: translateY(0);
              background-color: #2a3545;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            }
  
            // Make plus buttons purple
            &:last-child {
              background-color: map-get(map-get($colors, accent), primary);
              
              &:hover {
                background-color: map-get(map-get($colors, accent), primary-hover);
              }
            }
          }
  
          span {
            text-align: center;
            padding: map-get($spacing, xs) map-get($spacing, sm);
            color: white;
            font-size: map-get(map-get($typography, sizes), md);
            font-weight: map-get(map-get($typography, weights), medium);
            min-width: 48px;
          }
        }
      }
    }
  
    &__superset {
      margin-top: map-get($spacing, md);
      // padding: 0 map-get($spacing, md);
  
      button {
        width: 100%;
        padding: map-get($spacing, md);
        background-color: #2a3545;
        border: none;
        border-radius: map-get($border-radius, md);
        color: white;
        font-size: map-get(map-get($typography, sizes), md);
        transition: all 0.2s ease;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  
        &:hover {
          background-color: #343f52;
          // transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
  
        &:active {
          transform: translateY(0);
          background-color: #2a3545;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        }
  
        &.active {
          background-color: map-get(map-get($colors, accent), primary);
          box-shadow: 0 2px 4px rgba(113, 97, 239, 0.3);
  
          &:hover {
            background-color: map-get(map-get($colors, accent), primary-hover);
          }
        }
      }
    }

    &.supersetted {
      border: 1px solid map-get(map-get($colors, accent), primary);
      position: relative;
      border-color: map-get(map-get($colors, accent), primary);
      background-color: lighten(map-get(map-get($colors, background), secondary), 5%);
      
      // Add margin for the connector line
      &:not(:last-child) {
        margin-bottom: map-get($spacing, xl);
      }
  
      // Connector line
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -#{map-get($spacing, xl)};
        transform: translateX(-50%);
        width: 2px;
        height: map-get($spacing, lg);
        background-color: map-get(map-get($colors, accent), primary);
        z-index: 1;
      }
  
      // Hide connector for last item in superset
      &:last-child:after {
        display: none;
      }
  
      // Special styling for superset info text
      .superset-info {
        color: map-get(map-get($colors, accent), primary);
        opacity: 0.8;
      }
    }
  }

  .exercise-item__header button {
    color: rgba(255, 255, 255, 0.6);
    background: none;
    border: none;
    cursor: pointer;
    padding: map-get($spacing, xs);
    border-radius: map-get($border-radius, sm);
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  
    &:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .view-workouts-button {
    width: 100%;
    text-align: center;
    padding: map-get($spacing, md);
    background-color: map-get(map-get($colors, background), secondary);
    border: 1px solid map-get(map-get($colors, background), tertiary);
    border-radius: map-get($border-radius, md);
    color: map-get(map-get($colors, text), secondary);
    transition: all 0.2s ease;
    cursor: pointer;
    margin-top: map-get($spacing, md);
  
    &:hover {
      background-color: map-get(map-get($colors, background), tertiary);
      color: map-get(map-get($colors, text), primary);
      border-color: map-get(map-get($colors, accent), primary);
    }
  }
  
  .program-card {
    background-color: map-get(map-get($colors, background), primary);
    padding: map-get($spacing, lg);
    border-radius: map-get($border-radius, md);
    border: 1px solid rgba(map-get(map-get($colors, text), primary), 0.1);
    transition: all 0.2s ease;
    margin-bottom: 1rem;
    
    h3 {
      font-size: map-get(map-get($typography, sizes), lg);
      font-weight: map-get(map-get($typography, weights), semibold);
      margin-bottom: map-get($spacing, md);
      color: map-get(map-get($colors, text), primary);
    }
  
    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  // Add these new styles to your existing SCSS file

  .workouts-header {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: map-get($spacing, md);
    margin-bottom: map-get($spacing, xl);
    padding: map-get($spacing, md) 0;
    border-bottom: 1px solid rgba(map-get(map-get($colors, text), primary), 0.1);
  
    h2 {
      font-size: map-get(map-get($typography, sizes), xl);
      font-weight: map-get(map-get($typography, weights), semibold);
      color: map-get(map-get($colors, text), primary);
      margin: 0;
    }
  }

  .back-to-programs-button {
    display: flex;
    align-items: center;
    gap: map-get($spacing, sm);
    margin: 0.5rem 0rem;
    background: none;
    border: none;
    color: map-get(map-get($colors, text), secondary);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: map-get(map-get($typography, sizes), sm);
  
    &:hover {
      color: map-get(map-get($colors, text), primary);
    }
  }

  .workouts-grid {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
    max-width: 800px; // Limit the width for better readability
    margin: 0 auto;
  }

  .exercise-item__superset button {
    background-color: #2a3545;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    
    &:hover {
      background-color: #343f52;
      // transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  
    &:active {
      transform: translateY(0);
      background-color: #2a3545;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  
    &.active {
      background-color: #7161EF;
      box-shadow: 0 2px 4px rgba(113, 97, 239, 0.3);
    }
  }