// Primary Colors
$primary-bg:#171717;
$secondary-bg: #2E2E2E;
$input-bg: #373737;     // Dark Gray (Background similar to ChatGPT)
$primary-text: #FFFFFF;     // White (Primary text color)
$secondary-text: #9CA3AF;   // Light Gray (Secondary text color)
$border: #8e8e8e;

// Secondary Colors
$secondary: #3B82F6;        // Blue (Buttons, links, highlights)
$secondary-hover: #2563EB;  // Blue (Hover state for buttons/links)

// Accent Colors
$accent: #10B981;           // Green (Success messages, confirmations)

// Error Colors
$error: #f81f1f;            // Red (Error messages, warnings)

// Background Colors
$bg-light: #F3F4F6;         // Light Gray (Sections with light background)
$bg-card: #374151;          // Medium Gray (Cards or containers)

// Border Colors
$border: #D1D5DB;           // Gray (Borders and dividers)