@import "./colors";

.programs-container {
    h2 {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    
    a {
        text-decoration: none;
        color: black;
    }

    // .programs-container__program {
    //     border: 1px solid black;
    //     background-color: aliceblue;
    //     margin-bottom: 1rem;
    //     text-decoration: none;
    //     font-size: large;
    //     padding: 0.5rem;
    // }

}

.workouts-container {
    h2 {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    
    a {
        text-decoration: none;
        color: black;
    }

    .workouts-container__workout {
        border: 1px solid $border;
        background-color: $input-bg;
        margin-bottom: 1rem;
        text-decoration: none;
        font-size: large;
        padding: 0.5rem;
    }
    .workouts-container__workout:hover {
        background-color: lighten($color: $input-bg, $amount: 15)
    }
}